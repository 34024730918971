@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap);
@font-face {
  font-family: "Tommy Soft";
  src: url(/static/media/TommySoftRegular.1a1eefe1.eot);
  src: local("☺"), url(/static/media/TommySoftRegular.f72b05d9.woff) format("woff"),
    url(/static/media/TommySoftRegular.2fe5d7ee.ttf) format("truetype"),
    url(/static/media/TommySoftRegular.764394d7.svg) format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tommy Soft";
  src: url(/static/media/TommySoftBold.c2d88da1.eot);
  src: local("☺"), url(/static/media/TommySoftBold.86e639e4.woff) format("woff"),
    url(/static/media/TommySoftBold.7127c945.ttf) format("truetype"),
    url(/static/media/TommySoftBold.dade5a58.svg) format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Tommy Soft";
  src: url(/static/media/TommySoftMedium.7abeb177.eot);
  src: local("☺"), url(/static/media/TommySoftMedium.dafba752.woff) format("woff"),
    url(/static/media/TommySoftMedium.330797eb.ttf) format("truetype"),
    url(/static/media/TommySoftMedium.30feb72e.svg) format("svg");
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 12px;
}
@media (min-width: 768px) {
  :root {
    font-size: 16px;
  }
}

.navbar {
  background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #fff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: -100%;
  transition: 850ms;
}

.nav-menu.active {
  right: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #060b26;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #1a83ff;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-color: #fff;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/* span {
  margin-left: 16px;
} */

/*
 * react-calendar-heatmap styles
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect {
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 1px;
  shape-rendering: geometricPrecision;
}
.react-calendar-heatmap rect:hover {
  stroke: rgba(0, 0, 0, 0.3);
  stroke-width: 1px;
}

/*
 * Flowly color scale
 */
.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-flowly-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-flowly-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-flowly-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-flowly-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-flowly-4 {
  fill: rgba(37, 78, 119, 0.92);
}

