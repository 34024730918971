/*
 * react-calendar-heatmap styles
 */

.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect {
  stroke: rgba(0, 0, 0, 0.1);
  stroke-width: 1px;
  shape-rendering: geometricPrecision;
}
.react-calendar-heatmap rect:hover {
  stroke: rgba(0, 0, 0, 0.3);
  stroke-width: 1px;
}

/*
 * Flowly color scale
 */
.react-calendar-heatmap .color-empty {
  fill: #eeeeee;
}

.react-calendar-heatmap .color-flowly-0 {
  fill: #ededed;
}
.react-calendar-heatmap .color-flowly-1 {
  fill: #acd5f2;
}
.react-calendar-heatmap .color-flowly-2 {
  fill: #7fa8d1;
}
.react-calendar-heatmap .color-flowly-3 {
  fill: #49729b;
}
.react-calendar-heatmap .color-flowly-4 {
  fill: rgba(37, 78, 119, 0.92);
}
