@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
@font-face {
  font-family: "Tommy Soft";
  src: url("./assets/fonts/TommySoftRegular.eot");
  src: local("☺"), url("./assets/fonts/TommySoftRegular.woff") format("woff"),
    url("./assets/fonts/TommySoftRegular.ttf") format("truetype"),
    url("./assets/fonts/TommySoftRegular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tommy Soft";
  src: url("./assets/fonts/TommySoftBold.eot");
  src: local("☺"), url("./assets/fonts/TommySoftBold.woff") format("woff"),
    url("./assets/fonts/TommySoftBold.ttf") format("truetype"),
    url("./assets/fonts/TommySoftBold.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Tommy Soft";
  src: url("./assets/fonts/TommySoftMedium.eot");
  src: local("☺"), url("./assets/fonts/TommySoftMedium.woff") format("woff"),
    url("./assets/fonts/TommySoftMedium.ttf") format("truetype"),
    url("./assets/fonts/TommySoftMedium.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1rem;
  line-height: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  font-size: 12px;
}
@media (min-width: 768px) {
  :root {
    font-size: 16px;
  }
}
